webshop.ProductList = class {
	/* Options:
		- items: Items
		- settings: Webshop Settings
		- products_section: Products Wrapper
		- preference: If preference is not list view, render but hide
	*/
	constructor(options) {
		Object.assign(this, options);

		if (this.preference !== "List View") {
			this.products_section.addClass("hidden");
		}

		this.products_section.empty();
		this.make();
	}

	make() {
		let me = this;
		let html = `<br><br>`;

		this.items.forEach(item => {
			let title =  item.item_name || item.item_code || item.web_item_name || "";
			title =  title.length > 200 ? title.substr(0, 200) + "..." : title;

			html += `<div class='row list-row w-100 mb-4'>`;
			html += me.get_image_html(item, title, me.settings);
			html += me.get_row_body_html(item, title, me.settings);
			html += `</div>`;
		});

		let $product_wrapper = this.products_section;
		$product_wrapper.append(html);
	}

	get_image_html(item, title, settings) {
		let image = item.website_image;
		let wishlist_enabled = !item.has_variants && settings.enable_wishlist;
		let image_html = ``;

		if (image) {
			image_html += `
				<div class="col-2 border text-center rounded list-image">
					<a class="product-link product-list-link" href="/${ item.route || '#' }">
						<img itemprop="image" class="website-image h-100 w-100" alt="${ title }"
							src="${ image }">
					</a>
					${ wishlist_enabled ? this.get_wishlist_icon(item): '' }
				</div>
			`;
		} else {
			image_html += `
				<div class="col-2 border text-center rounded list-image">
					<a class="product-link product-list-link" href="/${ item.route || '#' }"
						style="text-decoration: none">
						<div class="card-img-top no-image-list">
							${ frappe.get_abbr(title) }
						</div>
					</a>
					${ wishlist_enabled ? this.get_wishlist_icon(item): '' }
				</div>
			`;
		}

		return image_html;
	}

	get_row_body_html(item, title, settings) {
		let body_html = `<div class='col-10 text-left'>`;
		body_html += this.get_title_html(item, title, settings);
		body_html += this.get_item_details(item, settings);
		body_html += `</div>`;
		return body_html;
	}

	get_title_html(item, title, settings) {
		let title_html = `<div style="display: flex; margin-left: -15px;">`;
		title_html += `
			<div class="col-8" style="margin-right: -15px;">
				<a class="" href="/${ item.route || '#' }"
					style="color: var(--gray-800); font-weight: 500;">
					${ title }
				</a>
			</div>
		`;

		if (settings.enabled) {
			title_html += `<div class="col-4 cart-action-container ${item.in_cart ? 'd-flex' : ''}">`;
			title_html += this.get_primary_button(item, settings);
			title_html += `</div>`;
		}
		title_html += `</div>`;

		return title_html;
	}

	get_item_details(item, settings) {
		let details = `
			<p class="product-code">

				${ item.item_group } | Item Code : ${ item.item_code } <br><br> Manufacturer: <b>${ item.brand || '' }</b> | MFR Part No: <b>${ item.custom_mfg_partno || '' }	</b> 			

			</p>
			<div class="mt-2" style="color: var(--gray-600) !important; font-size: 13px;">
				${ item.short_description || '' }
			</div>
			<div class="product-price" itemprop="offers" itemscope itemtype="https://schema.org/AggregateOffer">
				
			${ item.formatted_price !== undefined ? String(item.formatted_price)  : `<span class="out-of-stock mt-2" style="color: #ff6666;" >${ __( "Price Not Available") }</span>`}
		`;

		if (item.formatted_mrp) {
			details += `
				<small class="striked-price">
					<s>${ String(item.formatted_mrp) ? item.formatted_mrp.replace(/ +/g, "") : "" }</s>
				</small>
				<small class="ml-1 product-info-green">
					${ item.discount } OFF
				</small>
			`;
		}

		details += this.get_stock_availability(item, settings);
		details += `</div>`;

		return details;
	}

	get_stock_availability(item, settings) {
		if (settings.show_stock_availability && !item.has_variants) {
			if (item.on_backorder) {
				return `
					<br>
					<span class="out-of-stock mt-2" style="color: var(--primary-color)">
						${ __("Available on backorder") }
						  <br>
                        ${ __("Lead Time To Be Confirmed") }
					</span>
				`;
			} else if (!item.in_stock) {
				return `
					<br>
					<span class="out-of-stock mt-2">${ __("Lead Time To Be Confirmed") }</span>
				`;
			} else if (item.in_stock) {
				return `
					<br>
					<span class="in-stock in-green has-stock mt-2"
						style="font-size: 14px;"> 
						Availability : <b>${format_number(item.in_stock,'#,##,###.##',0)}</b> ${item.web_long_description.split("|")[0].split(':')[1]}
						<br>
						${ __("Subject To Prior Sale") } </span>
				`;
			}
		}
		return ``;
	}

	get_wishlist_icon(item) {
		let icon_class = item.wished ? "wished" : "not-wished";

		return `
			<div class="like-action-list ${ item.wished ? "like-action-wished" : ''}"
				data-item-code="${ item.item_code }">
				<svg class="icon sm">
					<use class="${ icon_class } wish-icon" href="#icon-heart"></use>
				</svg>
			</div>
		`;
	}

	get_primary_button(item, settings) {
        // Check conditions to show "Add to Quote" or "Add to Cart" button
        let show_add_to_quote = (!item.in_stock) || (!item.formatted_price || item.on_backorder);
    
        if (item.has_variants) {
            return `
                <a href="/${ item.route || '#' }">
                    <div class="btn btn-sm btn-explore-variants w-100 mt-4">
                        ${ __('Explore') }
                    </div>
                </a>
            `;
        } else if (settings.enabled && (settings.allow_items_not_in_stock || item.in_stock)) {
            if (show_add_to_quote) {
                return `
                <div class='btns-container' style='display: flex; flex-direction: column'>
					<div id="${ item.name }" class="btn btn-sm btn-primary btn-add-to-cart-list ${ item.in_cart ? 'hidden' : '' }"
                        data-item-code="${ item.item_code }">
                        <span class="mr-2">
                            <svg class="icon icon-md">
                                <use href="#icon-assets"></use>
                            </svg>
                        </span>
                        ${__('Add to Order')}
                    </div>

                    <div id="${ item.name }" class="btn btn-sm btn-primary btn-add-to-quote-list ${ item.in_cart ? 'hidden' : '' }"
                        data-item-code="${ item.item_code }">
                        <span class="mr-2">
                            <svg class="icon icon-md">
                                <use href="#icon-assets"></use>
                            </svg>
                        </span>
                        ${ __('Add to RFQ') }
                    </div>
					

                </div>
				 <a href="/cart">
                        <div id="${ item.name }"   class="btn btn-sm btn-primary go-to-cart-grid ${ item.in_cart ? '' : 'hidden' }" data-item-code="${ item.item_code }">
						  <span class="mr-2">
                            <svg class="icon icon-md">
                                <use href="#icon-assets"></use>
                            </svg>
                        </span>
                            ${ __('Go to Cart') }
                        </div>
                </a>
                
             

                `;
            }
        else {
            return `
                <div id="${ item.name }" class="btn
                    btn-sm btn-primary btn-add-to-cart-list
                    w-100 mt-2 ${ item.in_cart ? 'hidden' : '' }"
                    data-item-code="${ item.item_code }">
                    <span class="mr-2">
                        <svg class="icon icon-md">
                            <use href="#icon-assets"></use>
                        </svg>
                    </span>
                    ${__('Add to Order')}
                </div>

                <a href="/cart">
                    <div id="${ item.name }" class="btn
                        btn-sm btn-primary
                        w-100 mt-2 go-to-cart-grid
                        ${ item.in_cart ? '' : 'hidden' }"
                        data-item-code="${ item.item_code }">
							  <span class="mr-2">
                            <svg class="icon icon-md">
                                <use href="#icon-assets"></use>
                            </svg>
                        </span>
                        ${ __('Go to Cart') }
                    </div>
                </a>
            `;
        }
    }    
    else {
            return ``;
        }
    }

};
